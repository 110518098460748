import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { differentAddress } from '@app/energy-transfer/meter-readings/containers/meter-readings-current-supplier/meter-readings-current-supplier.constants';
import { EnergyStopSuppliersFormComponent } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.component';
import { EnergyStopForm } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.constants';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { Address, DbUtils, skipWhileEmpty } from '@smooved/core';
import { UiContext } from '@smooved/ui';
import { take } from 'rxjs/operators';
import { formControlNames } from './update-leaver-suppliers.constants';
import { EnergyStop as EnergyStopOld } from '@app/wizard/energy/interfaces/energy-stop';
import { EnergyStopActiveServices } from '@app/services/energy-stop/enums/energy-stop-active-services';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { ServiceStatus } from '@app/services/enums/service-status.enum';
import { MoveUtils } from '@app/move/state/move.utils';

@Component({
    selector: 'app-update-leaver-suppliers-modal',
    templateUrl: './update-leaver-suppliers.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UpdateLeaverSuppliersModal implements OnInit {
    @ViewChild(EnergyStopSuppliersFormComponent) suppliersForm: EnergyStopSuppliersFormComponent;

    @Input() public leaver: Move;
    @Input() public transferee: Move;

    public readonly energyStopStore = inject(EnergyStopStore);

    public form: UntypedFormGroup;
    public readonly formControlNames = formControlNames;
    public readonly i18nKeys = AppI18nKeyType;
    public readonly uiContext = UiContext;
    public readonly differentAddressControl = this.fb.control(null);
    public readonly differentAddress = differentAddress;
    public readonly buttonLoading$ = this.uiSandbox.patchLoading$;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly dialogRef: MatDialogRef<UpdateLeaverSuppliersModal>
    ) {}

    public ngOnInit(): void {
        const { transferee, leaver } = MoveUtils.getMovers(this.leaver);

        this.form = this.fb.group({
            [formControlNames.suppliers]: [this.energyStopStore.energyStop(), Validators.required],
            [formControlNames.contactAddress]: this.leaver.user?.contactAddress,
        });

        if (this.energyStopStore.energyStop().status === ServiceStatus.Submitted) {
            this.form.disable();
            this.differentAddressControl.disable();
        }

        this.differentAddressControl.patchValue(!!this.form.get(formControlNames.contactAddress).value);
    }

    public async onSubmit(): Promise<void> {
        if (this.form.invalid) {
            this.suppliersForm.form.markAllAsTouched();
            this.form.markAllAsTouched();
            return;
        }

        const { energyStop } = this.form.get(formControlNames.suppliers).value as EnergyStopForm;
        const contactAddress = this.form.get(formControlNames.contactAddress).value as Address;

        this.uiSandbox.showLoadingOverlay();
        await this.updateEnergyStopService(energyStop);
        this.updateEnergyStop(energyStop, contactAddress, () => {
            this.uiSandbox.hideLoadingOverlay();
            this.onMovePatched();
        });
    }

    private updateEnergyStop(energyStop: Partial<EnergyStopOld>, contactAddress: Address, callback?: () => void): void {
        this.moveSandbox.patchProperty(
            '',
            {
                energyStop: { invoiceCommunication: energyStop.invoiceCommunication },
                user: { contactAddress },
            },
            true,
            callback,
            false,
            this.leaver,
            true,
            false,
            true
        );
    }

    private async updateEnergyStopService(energyStop: Partial<EnergyStopOld>): Promise<void> {
        const payload = {
            energyStopActiveServices: energyStop.energyStopActiveServices as unknown as EnergyStopActiveServices,
            supplierElectricity: [EnergyStopActiveServices.Both, EnergyStopActiveServices.Electricity].includes(
                energyStop.energyStopActiveServices as unknown as EnergyStopActiveServices
            )
                ? energyStop.currentElectricitySupplier
                : null,
            supplierGas: [EnergyStopActiveServices.Both, EnergyStopActiveServices.Gas].includes(
                energyStop.energyStopActiveServices as unknown as EnergyStopActiveServices
            )
                ? energyStop.hasSameSupplier &&
                  (energyStop.energyStopActiveServices as unknown as EnergyStopActiveServices) === EnergyStopActiveServices.Both
                    ? energyStop.currentElectricitySupplier
                    : energyStop.currentGasSupplier
                : null,
        };
        this.energyStopStore.patch(DbUtils.getStringId(this.transferee), payload);
    }

    private onMovePatched = (): void => {
        this.moveSandbox
            .get(DbUtils.getStringId(this.leaver))
            .pipe(skipWhileEmpty(), take(1))
            .subscribe((move) => this.dialogRef.close(move));
    };
    protected readonly ServiceStatus = ServiceStatus;
}
