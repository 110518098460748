import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize, ModalSandbox, UiContext } from '@smooved/ui';
import { take } from 'rxjs/operators';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { ServiceStatus } from '@app/services/enums/service-status.enum';
import { MoveUtils } from '@app/move/state/move.utils';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-admin-todo-leaver-regularization-bill-requested',
    templateUrl: './todo-leaver-regularization-bill-requested.component.html',
    styles: [':host { display: block }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverRegularizationBillRequestedComponent extends BaseServiceActionComponent {
    @Input() public move: Move;
    @Input() public energyStop: EnergyStop;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;
    public readonly i18nKeys = AppI18nKeyType;
    protected readonly ServiceStatus = ServiceStatus;

    public readonly energyStopStore = inject(EnergyStopStore);
    private readonly modalSandbox = inject(ModalSandbox);
    private readonly translateService = inject(TranslateService);
    private readonly documentCenter = inject(DocumentCenterSandbox);
    private readonly adminModalService = inject(AdminModalService);
    protected readonly cdr;
    protected readonly moveSandbox;

    constructor() {
        const cdr = inject(ChangeDetectorRef);
        const moveSandbox = inject(MoveSandbox);
        super(cdr, moveSandbox);

        this.cdr = cdr;
        this.moveSandbox = moveSandbox;
    }

    public downloadEDS(): void {
        this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(this.move));
    }

    public requestBill(): void {
        this.adminModalService.openCustomMessageLeaverToSupplierModal(this.move, this.onBillRequested);
    }

    private onBillRequested = (move: Move): void => {
        if (move) {
            this.moveSandbox
                .get(DbUtils.getStringId(move))
                .pipe(take(1))
                .subscribe((move) => this.updateMove(move));
        }
    };

    public check(): void {
        const config = {
            header: this.translateService.instant('ADMIN.DASHBOARD.DETAIL.ENERGY_STOP.SUBMITTED.CONFIRM_MODAL.TITLE') as string,
            question: this.translateService.instant('ADMIN.DASHBOARD.DETAIL.ENERGY_STOP.SUBMITTED.CONFIRM_MODAL.TEXT') as string,
        };
        this.modalSandbox.openConfirmModal(config, this.confirmCheck, config, this.confirmCheck);
    }

    public uncheck(): void {
        const { transferee } = MoveUtils.getMovers(this.move);
        this.energyStopStore.setSubmitted(DbUtils.getStringId(transferee), false);
    }

    private confirmCheck = (confirm: boolean): void => {
        if (!confirm) return;
        const { transferee } = MoveUtils.getMovers(this.move);
        this.energyStopStore.setSubmitted(DbUtils.getStringId(transferee), true);
    };
    protected readonly appI18nKeyType = AppI18nKeyType;
}
