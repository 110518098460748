app-contact-log-modal {
    .__content {
        width: 100%;
        max-width: 600px; // same as emails

        ul {
            list-style: initial !important;
        }
    }
}
