<app-todo
    *ngIf="energyStop"
    [label]="'DETAIL.ENERGY_STOP.SUPPLIERS_ADDED' | i18nKeyTranslate: i18nKeys.AdminDashboard"
    [checked]="energyStop.states.suppliersStatus === EnergyStopSuppliersStatus.Complete"
>
    <app-todo-template *todoStateChecked>
        <a app-button [appearance]="buttonAppearance.Link" [size]="buttonSize.Sm" (click)="editSuppliers()">
            {{ 'VIEW' | translate }}
        </a>
    </app-todo-template>
    <app-todo-template *todoStateUnchecked>
        <a
            app-button
            [appearance]="buttonAppearance.Stroked"
            [context]="uiContext.Secondary"
            (click)="resendInvite()"
            [size]="buttonSize.Sm"
        >
            {{ 'RESEND_INVITE' | translate }}
        </a>
        <a app-button [context]="uiContext.Secondary" (click)="editSuppliers()" [size]="buttonSize.Sm">
            {{ 'ADD' | translate }}
        </a>
    </app-todo-template>
</app-todo>
