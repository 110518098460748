@import '../../../../../projects/ui/src/lib/scss/base/variables';
@import '../../../../../projects/ui/src/lib/scss/base/mixins';

app-nps-reviews-filter {
    app-menu {
        app-menu-item {
            &.__menu-item-button .__menu-item.is-active:not(.__menu-item--disabled):after,
            &.__menu-item-button .__menu-item:hover:not(.__menu-item--disabled):after {
                border-radius: var(--border-radius-xxl) !important;
            }

            &:not(.__menu-item-link) .__menu-item {
                border-bottom: none !important;
                padding: 0.5rem 1rem !important;

                @include phone-landscape-up() {
                    padding: 0.5rem 0 !important;
                }

                @include up-to-and-including-phone-portrait() {
                    margin: 0 !important;
                }
            }
        }
    }
}
