import { Injectable } from '@angular/core';
import { AdminMeterInfoModal } from '@app/energy/modals/admin-meter-info/admin-meter-info.modal';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { UpdateLeaverSuppliersModal } from '@app/move/modals/update-leaver-suppliers/update-leaver-suppliers.component';
import { MoveUtils } from '@app/move/state/move.utils';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AgentFormModalComponent } from '@app/real-estate-agent/modals/agent-form/agent-form.modal';
import { EnergyDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/energy-document-asset-detail/energy-document-asset-detail.component';
import { EnergyMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/energy-meter-reading-asset-detail/energy-meter-reading-asset-detail.component';
import { MobileMoveEnergyMetersComponent } from '@app/real-estate-agent/modals/mobile-move-energy-meters/mobile-move-energy-meters.component';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, I18nKeyUtils, MoveTransactionType } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { AppI18nKeyType, i18nKeyTypeMap } from '../../shared/constants/i18n-key-type-map';
import { AdminProvidersModule } from '../admin-providers.module';
import { MailLeaverToSupplierModal } from '../modals/mail-leaver-to-supplier/mail-leaver-to-supplier.modal';

@Injectable({
    providedIn: AdminProvidersModule,
})
export class AdminModalService {
    constructor(
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {}

    public openMeterInfo(move: Move, readOnly: boolean, callback?: (move: Move) => void): void {
        const data: ModalDataMove = {
            move,
            readOnly,
            asTransactionType: MoveUtils.isLeaver(move) ? MoveTransactionType.Rental : move.moveStates.transactionType,
        };

        this.modalSandbox.openModal(MobileMoveEnergyMetersComponent, { data }, null, AdminMeterInfoModal, { data }, callback);
    }

    public openUpdateLeaverSuppliersModal(move: Move, callback?: (move: Move) => void): void {
        const { leaver, transferee } = MoveUtils.getMovers(move);
        this.modalSandbox.openClosableModal({
            component: UpdateLeaverSuppliersModal,
            config: {
                header: this.translateService.instant(
                    I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.AdminDashboard, 'DETAIL.ENERGY_STOP.EDIT_SUPPLIERS')
                ) as string,
                subheader: transferee.user.formattedMovingAddress,
                input: { leaver, transferee },
            },
            afterClosed: callback,
        });
    }

    public openCustomMessageLeaverToSupplierModal(move: Move, callback?: (move: Move) => void): void {
        const data = DbUtils.getStringId(move);

        this.modalSandbox.openDialog({
            componentForMobile: MailLeaverToSupplierModal,
            configForMobile: { data },
            callbackForMobile: callback,
            componentForTabletPortraitUp: MailLeaverToSupplierModal,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: callback,
        });
    }

    public openEnergyMeterReadingAssetModal(file: FileInput, move: Move, callback?: (move: Move) => void): void {
        const data = {
            moveId: DbUtils.getStringId(move),
            file,
        };

        this.modalSandbox.openDialog({
            componentForMobile: EnergyMeterReadingAssetDetailComponent,
            configForMobile: { data },
            callbackForMobile: callback,
            componentForTabletPortraitUp: EnergyMeterReadingAssetDetailComponent,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: callback,
        });
    }

    public openEnergyDocumentAssetModal(file: FileInput, move: Move, callback?: (move: Move) => void): void {
        const data = {
            moveId: DbUtils.getStringId(move),
            file,
        };

        this.modalSandbox.openDialog({
            componentForMobile: EnergyDocumentAssetDetailComponent,
            configForMobile: { data },
            callbackForMobile: callback,
            componentForTabletPortraitUp: EnergyDocumentAssetDetailComponent,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: callback,
        });
    }

    public openAddRealEstateAgentModal(realEstateGroup: RealEstateGroup, callback?: (...args) => void): void {
        const data = {
            invite: false,
            realEstateGroup,
        };

        this.modalSandbox.openDialog({
            componentForMobile: AgentFormModalComponent,
            configForMobile: { data },
            callbackForMobile: callback,
            componentForTabletPortraitUp: AgentFormModalComponent,
            configForTabletPortraitUp: { data },
            callbackForTabletPortraitUp: callback,
        });
    }
}
