@import '../../../../../projects/ui/src/lib/scss/base/variables';

smvd-app-mention-input {
    display: inline-block;

    textarea {
        resize: none !important;
    }

    .__container {
        &:not(.__container--disabled) {
            border: 1px solid $color-gray-light;
            border-radius: $common-border-radius;
            padding: 0.25rem 0.5rem;
        }
    }

    .tagify {
        color: $color-text !important;

        &[disabled] {
            background: transparent;
            filter: none;
            opacity: 1;
        }
    }
}
