import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { DropdownInput } from '@app/form/interfaces/dropdown-input';
import { addressOptional } from '@app/form/validators/address-optional.validator';
import { Gender } from '@app/move/enums/gender.enum';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { PaymentSandbox } from '@app/payment/sandboxes/payment.sandbox';
import { FormControlName, languageOptions, legalFormOptions } from '@app/real-estate-agent/modals/move-edit/move-edit.constants';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { I18nKeyType, Role } from '@smooved/core';
import { ClosableModalTemplateComponent, NotificationSandbox, UiContext, VatNumberValidators } from '@smooved/ui';
import { takeUntil } from 'rxjs/operators';
import { RxjsComponent } from '@smooved/core';

@Component({
    selector: 'app-move-edit-modal',
    templateUrl: './move-edit.modal.html',
    styleUrls: ['./move-edit.modal.scss'],
})
export class MoveEditModalComponent extends RxjsComponent implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public move: Move;
    public moveLeaver?: Move;

    public moverRole = MoverRole;

    public languageOptions = languageOptions;
    public legalFormOptions = legalFormOptions;

    public maxDateOfBirth: Date = new Date();

    public readonly i18nKeyType = I18nKeyType;
    public readonly appI18nKeyType = AppI18nKeyType;
    public readonly uiContext = UiContext;

    public movingDate: Date | null;

    public genderOptions: DropdownInput<Gender>[] = [
        {
            id: 'male',
            name: 'male',
            label: this.translateService.instant('MALE'),
            value: Gender.Male,
        },
        {
            id: 'female',
            name: 'female',
            label: this.translateService.instant('FEMALE'),
            value: Gender.Female,
        },
    ];

    public readonly formControlName = FormControlName;

    public form = this.formBuilder.group({
        [FormControlName.MovingAddress]: [null, [addressOptional]],
        [FormControlName.MovingDate]: [null, Validators.required],
        [FormControlName.FirstName]: [null],
        [FormControlName.LastName]: [null],
        [FormControlName.PhoneNumber]: [null],
        [FormControlName.Email]: [null],
        [FormControlName.Language]: [null],
        [FormControlName.DateOfBirth]: [null],
        [FormControlName.Gender]: [null],
        [FormControlName.BtwNumber]: [null],
        [FormControlName.AccountNumber]: [null, [this.accountNumberValidator()]],
        [FormControlName.NationalNumber]: [null],
        [FormControlName.PassportNumber]: [null],
        [FormControlName.FirstNameLeaver]: [null],
        [FormControlName.LastNameLeaver]: [null],
        [FormControlName.PhoneNumberLeaver]: [null],
        [FormControlName.EmailLeaver]: [null],
        [FormControlName.LanguageLeaver]: [null],
    });

    constructor(
        public moveSandbox: MoveSandbox,
        private authenticationSandbox: AuthenticationSandbox,
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private notificationSandbox: NotificationSandbox,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
        super();
    }

    public ngOnInit(): void {
        this.initData();
        this.form
            .get('movingDate')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((movingDate: Date) => {
                this.movingDate = movingDate;
            });
    }

    private initData(): void {
        this.moveSandbox.get(this.data).subscribe((move) => {
            if (move.professional) {
                this.form.addControl(FormControlName.CompanyName, this.formBuilder.control(move.companyName));
                this.form.addControl(FormControlName.VatNumber, this.formBuilder.control(move.vatNumber, VatNumberValidators.isValid));
                this.form.addControl(FormControlName.LegalForm, this.formBuilder.control(move.legalForm));
            }
            if (!!move.linkedMove && move.user?.role === 'transferee') {
                this.form.patchValue({
                    movingAddress: move?.user?.movingAddress,
                    movingDate: move?.movingDate,
                    firstName: move?.user?.firstName,
                    lastName: move?.user?.lastName,
                    phoneNumber: move?.user?.phoneNumber,
                    email: move?.user?.email,
                    language: move?.user?.language,
                    dateOfBirth: move?.user?.dateOfBirth,
                    gender: move?.user?.gender,
                    btwNumber: move?.user?.btwNumber,
                    accountNumber: move?.user?.accountNumber,
                    nationalNumber: move?.user?.nationalNumber,
                    passportNumber: move?.user?.passportNumber,
                    firstNameLeaver: (move?.linkedMove as Move)?.user?.firstName,
                    lastNameLeaver: (move?.linkedMove as Move)?.user?.lastName,
                    phoneNumberLeaver: (move?.linkedMove as Move)?.user?.phoneNumber,
                    emailLeaver: (move?.linkedMove as Move)?.user?.email,
                    languageLeaver: (move?.linkedMove as Move)?.user?.language,
                });
                this.moveLeaver = move?.linkedMove as Move;
                this.addValidationForMoveAndMoveLeaverByRole(move, move?.linkedMove as Move);
            } else {
                this.form.patchValue({
                    movingAddress: move?.user?.movingAddress,
                    movingDate: move?.movingDate,
                    firstName: move?.user?.firstName,
                    lastName: move?.user?.lastName,
                    phoneNumber: move?.user?.phoneNumber,
                    email: move?.user?.email,
                    language: move?.user?.language,
                    dateOfBirth: move?.user?.dateOfBirth,
                    gender: move?.user?.gender,
                    btwNumber: move?.user?.btwNumber,
                    accountNumber: move?.user?.accountNumber,
                    nationalNumber: move?.user?.nationalNumber,
                    passportNumber: move?.user?.passportNumber,
                });
                this.addValidationForMoveByRole(move);
            }
            this.move = move;
            this.movingDate = move.movingDate;
        });
    }

    private addValidationForMoveByRole(move: Move): void {
        this.authenticationSandbox.roleOnce$.subscribe((role) => {
            if (role !== Role.Admin) {
                if (move?.energyOrderedByAdmin || move?.telecomOrderedByAdmin) {
                    this.movingAddressFormControl().disable();
                    this.movingDateFormControl().disable();
                    this.firstNameFormControl().disable();
                    this.lastNameFormControl().disable();
                    this.phoneNumberFormControl().disable();
                }
            }
        });
    }

    private addValidationForMoveAndMoveLeaverByRole(move: Move, moveLeaver: Move): void {
        this.authenticationSandbox.roleOnce$.subscribe((role) => {
            if (role !== Role.Admin) {
                if (move?.energyOrderedByAdmin || move?.telecomOrderedByAdmin) {
                    this.movingAddressFormControl().disable();
                    this.movingDateFormControl().disable();
                    this.firstNameFormControl().disable();
                    this.lastNameFormControl().disable();
                    this.phoneNumberFormControl().disable();
                }

                if (moveLeaver?.energyOrderedByAdmin || moveLeaver?.telecomOrderedByAdmin) {
                    this.firstNameFormControl().disable();
                    this.lastNameFormControl().disable();
                    this.phoneNumberFormControl().disable();
                }
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;

        const payload: Pick<Move, 'movingDate' | 'user' | 'companyName' | 'vatNumber' | 'legalForm'> = {
            movingDate: this.movingDateFormControl().value,
            user: {
                movingAddress: this.movingAddressFormControl().value,
                firstName: this.firstNameFormControl().value,
                lastName: this.lastNameFormControl().value,
                phoneNumber: this.phoneNumberFormControl().value,
                email: this.emailFormControl().value,
                language: this.languageFormControl().value,
                dateOfBirth: this.dateOfBirthFormControl().value,
                gender: this.genderFormControl().value,
                btwNumber: this.btwNumberFormControl().value,
                accountNumber: this.accountNumberFormControl().value,
                nationalNumber: this.nationalNumberFormControl().value,
                passportNumber: this.passportNumberFormControl().value,
            },
        };

        if (this.move.professional) {
            payload.companyName = this.companyNameFormControl().value;
            payload.vatNumber = this.vatNumberFormControl().value;
            payload.legalForm = this.form.get(FormControlName.LegalForm).value;
        }

        this.moveSandbox.patchProperty(
            '',
            payload,
            true,
            (transferee) => {
                if (this.moveLeaver) {
                    this.moveSandbox.patchProperty(
                        '',
                        {
                            user: {
                                firstName: this.firstNameLeaverFormControl().value,
                                lastName: this.lastNameLeaverFormControl().value,
                                phoneNumber: this.phoneNumberLeaverFormControl().value,
                                email: this.emailLeaverFormControl().value,
                                language: this.languageLeaverFormControl().value,
                            },
                        },
                        true,
                        () => {
                            this.closeModal(transferee);
                        },
                        false,
                        this.moveLeaver,
                        false,
                        true
                    );
                } else {
                    this.closeModal(transferee);
                }
            },
            false,
            this.move,
            false,
            true
        );
    }

    public onCancel(): void {
        this.closableModalComponent.close();
    }

    private closeModal(move: Move) {
        this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
        this.closableModalComponent.close(move);
    }

    private emailFormControl(): AbstractControl {
        return this.form?.get('email');
    }

    private firstNameFormControl(): AbstractControl {
        return this.form?.get('firstName');
    }

    private lastNameFormControl(): AbstractControl {
        return this.form?.get('lastName');
    }

    private phoneNumberFormControl(): AbstractControl {
        return this.form?.get('phoneNumber');
    }

    private languageFormControl(): AbstractControl {
        return this.form?.get('language');
    }

    private dateOfBirthFormControl(): AbstractControl {
        return this.form?.get('dateOfBirth');
    }

    private genderFormControl(): AbstractControl {
        return this.form?.get('gender');
    }

    private btwNumberFormControl(): AbstractControl {
        return this.form?.get('btwNumber');
    }

    private accountNumberFormControl(): AbstractControl {
        return this.form?.get('accountNumber');
    }

    private nationalNumberFormControl(): AbstractControl {
        return this.form?.get('nationalNumber');
    }

    private passportNumberFormControl(): AbstractControl {
        return this.form?.get('passportNumber');
    }

    private companyNameFormControl(): AbstractControl {
        return this.form?.get('companyName');
    }

    private vatNumberFormControl(): AbstractControl {
        return this.form?.get('vatNumber');
    }

    private movingAddressFormControl(): AbstractControl {
        return this.form?.get('movingAddress');
    }

    private movingDateFormControl(): AbstractControl {
        return this.form?.get('movingDate');
    }

    private emailLeaverFormControl(): AbstractControl {
        return this.form?.get('emailLeaver');
    }

    private firstNameLeaverFormControl(): AbstractControl {
        return this.form?.get('firstNameLeaver');
    }

    private lastNameLeaverFormControl(): AbstractControl {
        return this.form?.get('lastNameLeaver');
    }

    private phoneNumberLeaverFormControl(): AbstractControl {
        return this.form?.get('phoneNumberLeaver');
    }

    private languageLeaverFormControl(): AbstractControl {
        return this.form?.get('languageLeaver');
    }

    private accountNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!!control.value && !PaymentSandbox.isValidAccountNumber(control.value)) {
                return { accountNumberNotValid: true };
            }
            return null;
        };
    }
}
