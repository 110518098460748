import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { EnergyDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/energy-document-asset-detail/energy-document-asset-detail.component';
import { EnergyMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/energy-meter-reading-asset-detail/energy-meter-reading-asset-detail.component';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { I18nKeyType, Role } from '@smooved/core';
import { TranslateService } from '@ngx-translate/core';
import { ElectricityMeterType } from '@smooved/ui';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';

@Component({
    selector: 'app-energy-info-detail',
    template: `
        <app-move-detail-extra-info *ngIf="!!move?.energyOffer?.energyOfferSelection || !!move?.energyTransfer">
            <div ngProjectAs="header">
                <span *ngIf="!!move?.energyOffer?.energyOfferSelection"
                    >{{ move.energyOffer.energyOfferSelection.supplier }} -
                    {{
                        !!move.energyOffer.energyOfferSelection.nameLabels
                            ? move.energyOffer.energyOfferSelection.nameLabels[lang]
                            : move.energyOffer.energyOfferSelection.name
                    }}</span
                >
                <span *ngIf="!!move?.energyTransfer"
                    >{{ 'MOVE.ENERGY_TRANSFER' | translate }} - {{ move.energyTransfer.currentSupplier }}</span
                >
            </div>
            <div ngProjectAs="content">
                <ng-container *ngIf="!!move?.energyTransfer">
                    <app-info-detail [label]="'MOVE.CURRENT_SUPPLIER' | translate"
                        >{{ move.energyTransfer.currentSupplier }}
                    </app-info-detail>
                    <app-info-detail [label]="'MOVE.CLIENT_NUMBER' | translate">{{ move.energyTransfer.clientNumber }} </app-info-detail>
                </ng-container>

                <ng-container *ngIf="!!move.energyOffer" [ngSwitch]="move.energyOffer?.energyType">
                    <app-info-detail [label]="'MOVE.ENERGY_OFFER.METER_TYPE' | translate"
                        >{{ move.energyOffer.meterType }}
                    </app-info-detail>

                    <ng-container *ngIf="!!move.energyOffer?.energyOfferSelection">
                        <app-info-detail [label]="'MOVE.TOTAL_PRICE' | translate">
                            <div *ngSwitchCase="energyType.Electricity">
                                &euro; {{ move.energyOffer.energyOfferSelection.electricityTotalPrice }}
                            </div>
                            <div *ngSwitchCase="energyType.Gas">&euro; {{ move.energyOffer.energyOfferSelection.gasTotalPrice }}</div>
                            <div *ngSwitchCase="energyType.Both">
                                &euro; {{ move.energyOffer.energyOfferSelection.electricityGasTotalPrice }}
                            </div>
                        </app-info-detail>

                        <app-info-detail [label]="'MOVE.TOTAL_PRICE_INCLUSIVE_PROMOTIONS' | translate">
                            <ng-container *ngSwitchCase="energyType.Electricity">
                                &euro; {{ move.energyOffer.energyOfferSelection.electricityTotalPriceInclusivePromotions }}
                            </ng-container>
                            <ng-container *ngSwitchCase="energyType.Gas">
                                &euro; {{ move.energyOffer.energyOfferSelection.gasTotalPriceInclusivePromotions }}
                            </ng-container>
                            <ng-container *ngSwitchCase="energyType.Both">
                                &euro; {{ move.energyOffer.energyOfferSelection.electricityGasTotalPriceInclusivePromotions }}
                            </ng-container>
                        </app-info-detail>

                        <app-info-detail [label]="'MOVE.ENERGY.PAYMENT_TYPE' | translate">
                            <ng-container *ngIf="!!move.energyPaymentType">
                                {{ 'MOVE.ENERGY.PAYMENT_TYPE.' + move.energyPaymentType.toUpperCase() | translate }}
                            </ng-container>
                        </app-info-detail>
                    </ng-container>

                    <div
                        class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double"
                        *ngSwitchCase="
                            move.energyOffer.energyType === energyType.Electricity
                                ? energyType.Electricity
                                : move.energyOffer.energyType === energyType.Both
                                  ? energyType.Both
                                  : null
                        "
                    >
                        <p class="__title u-color-muted u-text-uppercase">{{ 'MOVE.ELECTRICITY' | translate }}</p>
                        <ng-container *ngIf="!!move.energyOffer?.energyOfferSelection" [ngSwitch]="move.energyOffer.meterType">
                            <app-info-detail *ngSwitchCase="getDoubleMeterType(move)" [label]="'MOVE.ENERGY.CONSUMPTION.DAY' | translate">
                                <app-copy-to-clipboard
                                    >{{ move.energyOffer.energyOfferSelection.electricityPricesDetails.electricityDoubleDay }}
                                </app-copy-to-clipboard>
                            </app-info-detail>

                            <app-info-detail *ngSwitchCase="getDoubleMeterType(move)" [label]="'MOVE.ENERGY.CONSUMPTION.NIGHT' | translate">
                                <app-copy-to-clipboard
                                    >{{ move.energyOffer.energyOfferSelection.electricityPricesDetails.electricityDoubleNight }}
                                </app-copy-to-clipboard>
                            </app-info-detail>

                            <app-info-detail
                                *ngSwitchCase="getDoubleMeterType(move)"
                                [label]="'MOVE.ENERGY.CONSUMPTION.EXCLUSIVE_NIGHT' | translate"
                            >
                                <app-copy-to-clipboard
                                    >{{ move.energyOffer.energyOfferSelection.electricityPricesDetails.electricityExclusiveNight }}
                                </app-copy-to-clipboard>
                            </app-info-detail>

                            <app-info-detail *ngSwitchDefault [label]="'MOVE.ENERGY.CONSUMPTION' | translate">
                                <app-copy-to-clipboard
                                    >{{
                                        move.energyOffer.energyOfferSelection.electricityPricesDetails?.electricitySingle ||
                                            move.energyOffer.energyOfferSelection.electricityPricesDetails?.electricityDoubleDay +
                                                move.energyOffer.energyOfferSelection.electricityPricesDetails?.electricityDoubleNight
                                    }}
                                </app-copy-to-clipboard>
                            </app-info-detail>
                        </ng-container>

                        <app-info-detail [label]="'MOVE.ENERGY.METER' | translate">
                            <app-copy-to-clipboard>{{ move.eanCodeElectricity }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail *ngIf="!!move?.eanCodeElectricityCurrent" [label]="'MOVE.ENERGY.METER.CURRENT' | translate">
                            <app-copy-to-clipboard>{{ move.eanCodeElectricityCurrent }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <ng-container [ngSwitch]="move.energyOffer.meterType">
                            <app-info-detail *ngSwitchCase="getDoubleMeterType(move)" [label]="'MOVE.ENERGY.READING.DAY' | translate">
                                <app-copy-to-clipboard>{{ move.electricityDoubleDayMeterReading }}</app-copy-to-clipboard>
                            </app-info-detail>

                            <app-info-detail *ngSwitchCase="getDoubleMeterType(move)" [label]="'MOVE.ENERGY.READING.NIGHT' | translate">
                                <app-copy-to-clipboard>{{ move.electricityDoubleNightMeterReading }}</app-copy-to-clipboard>
                            </app-info-detail>

                            <app-info-detail *ngSwitchDefault [label]="'MOVE.ENERGY.READING' | translate">
                                <app-copy-to-clipboard>{{ move.electricitySingleMeterReading }}</app-copy-to-clipboard>
                            </app-info-detail>
                        </ng-container>

                        <app-info-detail
                            *ngIf="!!move.electricityDoubleExclusiveNightMeterReading"
                            [label]="'MOVE.ENERGY.READING.EXCLUSIVE_NIGHT' | translate"
                        >
                            <app-copy-to-clipboard>{{ move.electricityDoubleExclusiveNightMeterReading }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *ngIf="move.energyDigitalMeterReadings?.automatic"
                            [label]="'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING' | translate"
                        >
                            <app-copy-to-clipboard>{{ move.movingDate | formatDate }}</app-copy-to-clipboard>
                        </app-info-detail>
                    </div>
                    <div
                        class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double"
                        *ngSwitchCase="
                            move.energyOffer.energyType === energyType.Gas
                                ? energyType.Gas
                                : move.energyOffer.energyType === energyType.Both
                                  ? energyType.Both
                                  : null
                        "
                    >
                        <p class="__title u-color-muted u-text-uppercase">{{ 'MOVE.GAS' | translate }}</p>

                        <app-info-detail *ngIf="!!move.energyOffer?.energyOfferSelection" [label]="'MOVE.ENERGY.CONSUMPTION' | translate">
                            <app-copy-to-clipboard>{{ move.energyOffer.energyOfferSelection.gasPricesDetails?.gas }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail [label]="'MOVE.ENERGY.METER' | translate">
                            <app-copy-to-clipboard>{{ move.eanCodeGas }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail *ngIf="!!move?.eanCodeGasCurrent" [label]="'MOVE.ENERGY.METER.CURRENT' | translate">
                            <app-copy-to-clipboard>{{ move.eanCodeGasCurrent }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail [label]="'MOVE.ENERGY.READING' | translate">
                            <app-copy-to-clipboard>{{ move.gasMeterReading }}</app-copy-to-clipboard>
                        </app-info-detail>
                    </div>

                    <div
                        *ngIf="move?.energyOffer?.hasSolarPanels"
                        class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double"
                    >
                        <p class="__title u-color-muted u-text-uppercase">{{ 'MOVE.ENERGY.SOLAR_PANELS.TITLE' | translate }}</p>

                        <app-info-detail [label]="'MOVE.ENERGY.SOLAR_PANELS.CONVERT_POWER' | translate">
                            <ng-container [ngSwitch]="!!move?.energyOffer?.convertPowerSolarPanelsUnknown">
                                <span *ngSwitchCase="false">{{ move?.energyOffer?.convertPowerSolarPanels }}kW</span>
                                <span *ngSwitchCase="true">{{ 'MOVE.ENERGY.SOLAR_PANELS.CONVERT_POWER_UNKNOWN' | translate }}</span>
                            </ng-container>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injection"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injection | kwh }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionSingle"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_SINGLE' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionSingle | pricePerKwh }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionDoubleDay"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_DOUBLE_DAY' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionDoubleDay | pricePerKwh }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionDoubleNight"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_PRICE_DOUBLE_NIGHT' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionDoubleNight | pricePerKwh }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFee"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFee | price }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFeeDoubleDay"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE_DOUBLE_DAY' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFeeDoubleDay | price }}
                            </app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *showIfNumber="move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFeeDoubleNight"
                            [label]="'MOVE.ENERGY.SUGGESTION.DETAILS.INJECTION_FEE_DOUBLE_NIGHT' | translate"
                        >
                            <app-copy-to-clipboard
                                >{{ move.energyOffer.energyOfferSelection?.electricityPricesDetails?.injectionFeeDoubleNight | price }}
                            </app-copy-to-clipboard>
                        </app-info-detail>
                    </div>

                    <div class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double">
                        <p class="__title u-color-muted u-text-uppercase">{{ 'MOVE.METER_READING.ASSETS' | translate }}</p>
                        <app-thumbnail
                            *ngFor="let file of move?.energyMeterReadingAssets; let last = last"
                            [file]="file"
                            class="u-margin-right"
                            (detail)="onEnergyMeterReadingAssetDetail($event)"
                        ></app-thumbnail>
                    </div>

                    <div class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double">
                        <p class="__title u-color-muted u-text-uppercase">{{ 'MOVE.ENERGY.DOCUMENT.ASSETS' | translate }}</p>
                        <app-thumbnail
                            *ngFor="let file of move?.energyDocumentAssets; let last = last"
                            [file]="file"
                            class="u-margin-right"
                            (detail)="onEnergyDocumentAssetDetail($event)"
                        ></app-thumbnail>
                    </div>
                    <div
                        class="u-bordered-top u-position-relative u-padding-top u-margin-bottom-double"
                        *ngIf="energyStop || move.meterReadingDateBy"
                    >
                        <p class="__title u-color-muted u-text-uppercase">
                            {{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.FINAL_INVOICE_LEAVER' | translate }}
                        </p>
                        <app-info-detail *ngIf="move.meterReadingDate" [label]="'ENERGY.METER_READING.DATE' | translate">
                            <app-copy-to-clipboard>{{ move.meterReadingDate | formatDate }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail
                            *ngIf="move.meterReadingDateBy"
                            [label]="'ADMIN.DASHBOARD.DETAIL.ENERGY.METER_READING_BY' | translate"
                        >
                            <app-copy-to-clipboard>
                                {{
                                    (move.meterReadingDateBy === roles.Mover
                                        ? (moverRole.Leaver | i18nKey: i18nKeys.MoverRole)
                                        : (move.meterReadingDateBy | i18nKey: i18nKeys.UserRole)
                                    ) | translate
                                }}
                            </app-copy-to-clipboard>
                        </app-info-detail>
                        <ng-container *ngIf="energyStop">
                            <p class="__sub-title u-font-size-sm u-color-muted">{{ 'ELECTRICITY' | translate }}</p>
                            <app-info-detail [label]="'MOVE.CURRENT_SUPPLIER' | translate">
                                <app-copy-to-clipboard>{{ energyStop.supplierElectricity }}</app-copy-to-clipboard>
                            </app-info-detail>

                            <p class="__sub-title u-font-size-sm u-color-muted">{{ 'GAS' | translate }}</p>
                            <app-info-detail [label]="'MOVE.CURRENT_SUPPLIER' | translate">
                                <app-copy-to-clipboard>{{ energyStop.supplierGas }}</app-copy-to-clipboard>
                            </app-info-detail>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </app-move-detail-extra-info>
    `,
    styleUrls: ['./energy-info-detail.component.scss'],
})
export class EnergyInfoDetailComponent {
    @Input() public move: Move;
    @Input() energyStop: EnergyStop;

    public readonly roles = Role;
    public readonly energyType = EnergyType;
    public readonly meterType = ElectricityMeterType;
    public readonly moverRole = MoverRole;
    public readonly i18nKeys = { ...I18nKeyType, ...AppI18nKeyType };

    public lang = this.translateService.currentLang;

    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService
    ) {}

    public getDoubleMeterType(move: Move): ElectricityMeterType {
        return move?.energyOffer?.meterType === ElectricityMeterType.Double &&
            ((!!move?.energyOffer?.energyOfferSelection?.electricityPricesDetails?.electricityDoubleDay &&
                !!move?.energyOffer?.energyOfferSelection?.electricityPricesDetails?.electricityDoubleNight) ||
                !!move?.energyTransfer)
            ? ElectricityMeterType.Double
            : null;
    }

    public onEnergyMeterReadingAssetDetail(file: FileInput) {
        this.dialog
            .open(EnergyMeterReadingAssetDetailComponent, {
                data: {
                    moveId: this.move._id,
                    file,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.move = move;
            });
    }

    public onEnergyDocumentAssetDetail(file: FileInput) {
        this.dialog
            .open(EnergyDocumentAssetDetailComponent, {
                data: {
                    moveId: this.move._id,
                    file,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.move = move;
            });
    }
}
