import { Injectable } from '@angular/core';
import { ContactLogModalComponent } from '@app/admin/modals/contact-log-modal/contact-log-modal.component';
import { CustomMessageModal } from '@app/admin/modals/custom-message/custom-message.modal';
import { AdminMeterInfoModal } from '@app/energy/modals/admin-meter-info/admin-meter-info.modal';
import { Escalation } from '@app/escalation/interfaces/escalation';
import { createContactLogTitleResource, customMessageModalTitle } from '@app/move/constants/move.constants';
import { ContactLog } from '@app/move/interfaces/contact-log';
import { Move } from '@app/move/interfaces/move';
import { CreateContactLogModal } from '@app/move/modals/create-contact-log/create-contact-log.modal';
import { MoveModalModule } from '@app/move/move-modal.module';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { MobileMoveEnergyMetersComponent } from '@app/real-estate-agent/modals/mobile-move-energy-meters/mobile-move-energy-meters.component';
import { MobileMoveWaterComponent } from '@app/real-estate-agent/modals/mobile-move-water/mobile-move-water.component';
import { WaterDocumentMovingAddressModal } from '@app/water/modals/water-document-moving-address/water-document-moving-address.modal';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox } from '@smooved/ui';
import { MoveSandbox } from './move.sandbox';
import { PosthogService } from '@app/tracking/posthog.service';
import { PostHogEventsEnum } from '@core/enums';

@Injectable({
    providedIn: MoveModalModule,
})
export class MoveModalSandbox {
    constructor(
        private readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService,
        private readonly posthogService: PosthogService
    ) {}

    public showMeterReadingsEnergy(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.modalSandbox.openModal(
                MobileMoveEnergyMetersComponent,
                {
                    data: {
                        move,
                        readOnly: true,
                    },
                },
                null,
                AdminMeterInfoModal,
                {
                    data: {
                        move,
                        readOnly: true,
                    },
                },
                null
            );
        });
        this.posthogService.sendEventForTransactionState(PostHogEventsEnum.ViewMeterReadings);
    }

    public showMeterReadingsWater(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            const data: ModalDataMove = {
                move,
                readOnly: !!move.moveStates?.waterDocumentsMovingAddressByAdmin,
            };

            this.modalSandbox.openModal(MobileMoveWaterComponent, { data }, null, WaterDocumentMovingAddressModal, { data }, null);
        });
    }

    public createContactLog(move: Move): void {
        this.modalSandbox.openClosableModal({
            component: CreateContactLogModal,
            config: {
                header: <string>this.translateService.instant(createContactLogTitleResource),
                input: {
                    move,
                },
            },
        });
    }

    public showContactLogInfo(contactLog: ContactLog): void {
        this.modalSandbox.openClosableModal({
            component: ContactLogModalComponent,
            config: {
                header: this.translateService.instant(this.moveSandbox.getLogTranslationLabel(contactLog), {
                    createdOn: contactLog?.formattedCreatedOn,
                }) as string,
                input: {
                    contactLog: contactLog,
                },
            },
        });
    }

    public showCustomMessage(
        input: {
            move: Move;
            escalation?: Escalation;
        },
        afterClosed?: (result: boolean) => void
    ): void {
        this.modalSandbox.openClosableModal({
            component: CustomMessageModal,
            config: {
                header: <string>this.translateService.instant(customMessageModalTitle),
                input,
                panelClass: '__modal-two-panel',
            },
            afterClosed,
        });
    }
}
