import { Injectable } from '@angular/core';
import { Thread } from '@app/message/interfaces/thread.interface';
import { CreateOrderGift } from '@app/order/interfaces/create-order-gift';
import { GetOrdersRequest } from '@app/order/interfaces/get-orders-request';
import { Order } from '@app/order/interfaces/order';
import { OrderService } from '@app/order/services/order.service';
import { PaginationResponse } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateOrderMortgage } from '../interfaces/create-order-mortgage';
import { OrderGift } from '../interfaces/order-gift';
import { OrderMortgage } from '../interfaces/order-mortgage';

@Injectable({
    providedIn: 'root',
})
export class OrderSandbox {
    constructor(
        private readonly orderService: OrderService,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public get(getOrdersRequest: GetOrdersRequest): Observable<PaginationResponse<Order>> {
        return this.orderService.get(getOrdersRequest);
    }

    public findById<T extends Order>(id: string): Observable<T> {
        return this.orderService.findById<T>(id);
    }

    public getMortgages(getOrdersRequest: GetOrdersRequest): Observable<PaginationResponse<OrderMortgage>> {
        return this.orderService.getMortgages(getOrdersRequest);
    }

    public patch(id: string, payload: any): Observable<Order> {
        return this.orderService.patch(id, payload);
    }

    public patchGift(id: string, payload: any): Observable<OrderGift> {
        return this.orderService.patchGift(id, payload);
    }

    public patchMoveByOrder(id: string, payload: any, __t?: string): Observable<Order> {
        return this.orderService.patchMoveByOrder(id, payload, __t);
    }

    public createOrderGift(payload: CreateOrderGift): Observable<void> {
        return this.orderService.createOrderGift(payload);
    }

    public createOrderMortgage(payload: CreateOrderMortgage): Observable<OrderMortgage> {
        return this.orderService.createOrderMortgage(payload);
    }

    public patchMortgage(id: string, payload: any): Observable<OrderMortgage> {
        return this.orderService.patchMortgage(id, payload);
    }

    public createMessage(id: string, message: string): Observable<Thread> {
        return this.orderService.createMessage(id, message).pipe(tap(() => this.notificationSandbox.sendSuccess()));
    }
}
