import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControlNames } from '@app/reviews/modals/edit-review/edit-review.constants';
import {
    ArrayUtils,
    CustomDatabaseValue,
    DbUtils,
    NpsReview,
    RealEstateAgency,
    RealEstateAgentUtils,
    ReviewSource,
    UpdateNpsReview,
} from '@smooved/core';
import {
    ClosableModalTemplateComponent,
    GroupedSearchableDropdownInputOptions,
    intervieweeOptions,
    ModalSandbox,
    SearchableDropdownInputOption,
    UiContext,
} from '@smooved/ui';
import { RealEstateAgentsGroupedByLocationDto } from '@app/real-estate-agent/interfaces/real-estate-agents-grouped-by-location';
import { RealEstateGroupService } from '@app/real-estate-group/services/real-estate-group.service';
import { delay, tap } from 'rxjs/operators';
import { RealEstateGroupUtils } from '@app/real-estate-group/utils/real-estate-group.utils';
import { forkJoin } from 'rxjs';
import { ReviewsService } from '@app/reviews/services/reviews.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-edit-review',
    templateUrl: 'edit-review.modal.html',
    styleUrls: ['./edit-review.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditReviewModal implements OnInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true }) modalRef: ClosableModalTemplateComponent;

    public readonly uiContext = UiContext;

    public readonly formControlNames = FormControlNames;
    public readonly intervieweeOptions = intervieweeOptions;

    public locationOptions: SearchableDropdownInputOption[] = [];
    public agentOptions: GroupedSearchableDropdownInputOptions[] = [];
    public agentOptionsOthers: SearchableDropdownInputOption[] = [];

    public loading: boolean = false;

    public form = this.formBuilder.group({
        [FormControlNames.GeneratedSummary]: null,
        [FormControlNames.Suggestion]: null,
        [FormControlNames.AssessedTo]: null,
        [FormControlNames.LinkedRealEstateAgents]: null,
        [FormControlNames.Location]: null,
        [FormControlNames.Score]: [null, [Validators.min(0), Validators.max(10)]],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: NpsReview,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly realEstateGroupService: RealEstateGroupService,
        private readonly reviewsService: ReviewsService,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        this.form.patchValue(this.data);
        if (this.data.source !== ReviewSource.Smooved) this.form.get(FormControlNames.Score).disable();
    }

    public ngOnInit(): void {
        this.setRealEstateAgentOptions();
    }

    public submit(): void {
        if (this.form.invalid) return;
        const value = this.form.getRawValue();
        const payload: UpdateNpsReview = {};
        if (this.data.generatedSummary !== value[FormControlNames.GeneratedSummary])
            payload.generatedSummary = value[FormControlNames.GeneratedSummary];
        if (this.data.suggestion !== value[FormControlNames.Suggestion]) payload.content = value[FormControlNames.Suggestion];
        if (this.data.score !== value[FormControlNames.Score]) payload.score = value[FormControlNames.Score];
        if (this.data.assessedTo !== value[FormControlNames.AssessedTo]) payload.assessedTo = value[FormControlNames.AssessedTo];
        if (!ArrayUtils.equal(this.data.linkedRealEstateAgents, value[FormControlNames.LinkedRealEstateAgents]))
            payload.linkedRealEstateAgents = value[FormControlNames.LinkedRealEstateAgents];
        if (this.data.location !== value[FormControlNames.Location]) payload.location = value[FormControlNames.Location];
        this.modalRef.close(ArrayUtils.isEmpty(Object.keys(payload)) ? null : payload);
    }

    public confirmDeleteReview() {
        const data = {
            data: this.translateService.instant('UI.REVIEWS.CONFIRM_DELETE'),
        };
        this.modalSandbox.openConfirmModal(data, this.deleteReview, data, this.deleteReview);
    }

    private deleteReview = (confirmed: boolean) => {
        if (!confirmed) return;
        this.loading = true;
        this.reviewsService.deleteReview(DbUtils.getStringId(this.data)).subscribe(() => {
            this.loading = false;
            this.modalRef.close({ deleted: true });
        });
    };

    private setRealEstateAgentOptions(): void {
        forkJoin([
            this.realEstateGroupService.getRealEstateAgentsGroupedByLocation(this.data.realEstateGroup),
            this.realEstateGroupService.getLocations(this.data.realEstateGroup),
        ])
            .pipe(
                tap(([agentsGroupedByLocations, locations]: [RealEstateAgentsGroupedByLocationDto, RealEstateAgency[]]) => {
                    this.agentOptionsOthers = agentsGroupedByLocations.otherRealEstateAgents.map(
                        RealEstateAgentUtils.mapRealEstateAgentToOption
                    );
                    this.agentOptions = agentsGroupedByLocations.groupedByLocationRealEstateAgents.map(
                        RealEstateAgentUtils.mapGroupedRealEstateAgentsToGroupedOptions
                    );
                    this.locationOptions = RealEstateGroupUtils.locationsOptionsFactory({ locations });
                }),
                delay(0)
            )
            .subscribe(() => {
                const linkedRealEstateAgents = this.data.linkedRealEstateAgents.map((rea) =>
                    rea.isOther ? CustomDatabaseValue.Other : rea.id
                );
                this.form.get(FormControlNames.LinkedRealEstateAgents).setValue(linkedRealEstateAgents);
                this.form
                    .get(FormControlNames.Location)
                    .setValue(
                        this.data.location === CustomDatabaseValue.Other
                            ? CustomDatabaseValue.Other
                            : DbUtils.getStringId(this.data.location)
                    );
            });
    }
}
