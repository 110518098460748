import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InsurancesSuggestion } from '@app/iots/interfaces/insurances-suggestion.interface';

@Component({
    selector: 'app-insurances-suggestion',
    template: `
        <app-card [padding]="true" [paddingDouble]="false" class="u-h100p u-flex-column u-position-relative">
            <app-ribbon *ngIf="!!suggestion?.preferred">{{ 'IOTS.SUGGESTIONS.HOPALA_CHOICE' | translate }}</app-ribbon>

            <div class="js-content u-flex-column u-flex-1 u-padding-top" [style.minHeight]="minHeight">
                <div class="__image-container u-flex-row u-flex-align-items-center u-flex-justify-content-center u-margin-bottom">
                    <img [src]="suggestion?.insurer?.logourl" class="h-[60px]" [alt]="'Logo' + suggestion?.product?.commercialnl?.name" />
                </div>

                <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-margin-bottom">
                    <div>
                        <h5>{{ suggestion?.insurer?.name }}</h5>
                        <p>{{ suggestion?.product?.commercialnl?.name }}</p>
                    </div>
                    <app-insurances-suggestion-review
                        [review]="suggestion?.insurer?.review"
                        class="u-margin-left u-display-inline-block"
                    ></app-insurances-suggestion-review>
                </div>

                <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.MANDATORY_INSURANCES' | translate }} *</h6>

                <app-check-list
                    class="u-display-block"
                    [content]="[suggestion?.product?.commercialnl?.usp2, suggestion?.product?.commercialnl?.usp3]"
                ></app-check-list>
            </div>

            <div class="u-flex-no-shrink">
                <app-expansion-panel class="u-display-block u-margin-bottom" *ngIf="type === 'fire'">
                    <span title class="u-link">{{ 'IOTS.SUGGESTIONS.LIMITS' | translate }}</span>
                    <div class="u-bordered-bottom u-padding-bottom-double">
                        <div class="u-margin-bottom-double">
                            <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.BUILDING' | translate }}</h6>
                            <app-insurances-suggestion-more-info
                                [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.BODILY_HARM' | translate"
                                [value]="suggestion?.product?.firemoreinfo?.bodilyharm"
                            ></app-insurances-suggestion-more-info>

                            <app-insurances-suggestion-more-info
                                [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.MATERIAL_DAMAGE' | translate"
                                [value]="suggestion?.product?.firemoreinfo?.materialdamage"
                            ></app-insurances-suggestion-more-info>

                            <app-insurances-suggestion-more-info
                                [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.ADVANCE' | translate"
                                [value]="suggestion?.product?.firemoreinfo?.advance"
                            ></app-insurances-suggestion-more-info>
                        </div>
                    </div>
                </app-expansion-panel>

                <app-expansion-panel class="u-display-block u-margin-bottom" *ngIf="type === 'familial'">
                    <span title class="u-link">{{ 'IOTS.SUGGESTIONS.LIMITS' | translate }}</span>
                    <div class="u-bordered-bottom u-padding-bottom-double">
                        <div class="u-margin-bottom-double">
                            <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.BUILDING' | translate }}</h6>
                            <app-insurances-suggestion-more-info
                                [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.BODILY_HARM' | translate"
                                [value]="suggestion?.product?.familialemoreinfo?.bodilyharm"
                            ></app-insurances-suggestion-more-info>

                            <app-insurances-suggestion-more-info
                                [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.MATERIAL_DAMAGE' | translate"
                                [value]="suggestion?.product?.familialemoreinfo?.materialdamage"
                            ></app-insurances-suggestion-more-info>
                        </div>
                    </div>
                </app-expansion-panel>

                <div class="u-flex-column u-flex-justify-content-end u-margin-bottom-double">
                    <div class="u-flex-column u-flex-align-items-end">
                        <div [ngSwitch]="type">
                            <div *ngSwitchCase="'fire'">
                                <span class="u-color-muted">{{ 'FROM' | translate }} </span>
                                <span class="u-color-accent u-font-size-large u-font-weight-semi-bold">{{
                                    suggestion?.premium?.itemfirebuildingyearly | price
                                }}</span>
                            </div>

                            <div *ngSwitchCase="'familial'" class="u-flex-column u-flex-align-items-end u-margin-top">
                                <div>
                                    <span class="u-color-muted">{{ 'FROM' | translate }} </span>
                                    <span class="u-color-accent u-font-size-large u-font-weight-semi-bold">{{
                                        suggestion?.premium?.famyearly | price
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="u-font-size-small u-color-muted">{{ 'PER_YEAR' | translate }}</span>
                    </div>
                </div>

                <div *ngIf="type === 'fire'">
                    <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.OPTIONAL_INSURANCES' | translate }} **</h6>

                    <app-check-list class="u-display-block" [content]="['IOTS.SUGGESTIONS.HOUSEHOLD_EFFECTS' | translate]"></app-check-list>
                    <div class="u-flex-column u-flex-align-items-end">
                        <div>
                            <span class="u-color-muted">{{ 'FROM' | translate }} </span>
                            <span class="u-color-accent u-font-weight-semi-bold">{{
                                suggestion?.premium?.itemfirecontentyearly | price
                            }}</span>
                        </div>
                        <span class="u-font-size-small u-color-muted">{{ 'PER_YEAR' | translate }}</span>
                    </div>

                    <app-check-list class="u-display-block" [content]="['IOTS.SUGGESTIONS.THEFT' | translate]"></app-check-list>
                    <div class="u-flex-column u-flex-align-items-end">
                        <div>
                            <span class="u-color-muted">{{ 'FROM' | translate }} </span>
                            <span class="u-color-accent u-font-weight-semi-bold">{{ suggestion?.premium?.itemfiretheftyearly | price }}</span>
                        </div>
                        <span class="u-font-size-small u-color-muted">{{ 'PER_YEAR' | translate }}</span>
                    </div>

                    <app-check-list class="u-display-block" [content]="['IOTS.SUGGESTIONS.LEGAL_COUNSEL' | translate]"></app-check-list>
                    <div class="u-flex-column u-flex-align-items-end">
                        <div>
                            <span class="u-color-muted">{{ 'FROM' | translate }} </span>
                            <span class="u-color-accent u-font-weight-semi-bold">{{ suggestion?.premium?.itemfirelegalyearly | price }}</span>
                        </div>
                        <span class="u-font-size-small u-color-muted">{{ 'PER_YEAR' | translate }}</span>
                    </div>

                    <app-expansion-panel class="u-display-block u-margin-bottom">
                        <span title class="u-link">{{ 'IOTS.SUGGESTIONS.LIMITS' | translate }}</span>
                        <div class="u-bordered-bottom u-padding-bottom-double">
                            <div class="u-margin-bottom-double">
                                <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.HOUSEHOLD_EFFECTS' | translate }}</h6>
                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.CONTENTS_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.contentslimit"
                                ></app-insurances-suggestion-more-info>

                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.COMPUTER_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.computerlimit"
                                ></app-insurances-suggestion-more-info>

                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.VEHICLE_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.vehiclelimit"
                                ></app-insurances-suggestion-more-info>
                            </div>

                            <div class="u-margin-bottom-double">
                                <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.THEFT' | translate }}</h6>
                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.JEWELERY_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.jewelerylimit"
                                ></app-insurances-suggestion-more-info>

                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.MONEY_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.moneylimit"
                                ></app-insurances-suggestion-more-info>
                            </div>

                            <div>
                                <h6 class="u-margin-bottom-half">{{ 'IOTS.SUGGESTIONS.LEGAL_COUNSEL' | translate }}</h6>
                                <app-insurances-suggestion-more-info
                                    [label]="'IOTS.SUGGESTIONS.FIRE_MORE_INFO.LEGAL_LIMIT' | translate"
                                    [value]="suggestion?.product?.firemoreinfo?.legallimit"
                                ></app-insurances-suggestion-more-info>
                            </div>
                        </div>
                    </app-expansion-panel>
                </div>

                <app-button class="u-w100p" theme="secondary" (onClick)="select.emit()">{{ 'CHOOSE.LABEL' | translate }}</app-button>
            </div>
        </app-card>
    `,
    styleUrls: ['./insurances-suggestion.component.scss'],
})
export class InsurancesSuggestionComponent {
    @Input() public suggestion: InsurancesSuggestion;
    @Input() public minHeight = '300px';
    @Input() public type: 'fire' | 'familial';
    @Output() public select: EventEmitter<void> = new EventEmitter<void>();
}
