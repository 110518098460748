import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminModalService } from '@app/admin/services/admin-modal.service';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { DbUtils, Role, SimpleChangesUtils } from '@smooved/core';
import { MoveUtils } from '@shared/move/utils/move.utils';
import { ButtonAppearance, ElectricityMeterType, UiContext } from '@smooved/ui';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { ElectricityMeterStore } from '@smooved-lib/lib/meters/store/electricity-meter.store';

@Component({
    selector: 'app-energy-stop-info-detail',
    templateUrl: './energy-stop-info-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyStopInfoDetailComponent implements OnInit, OnChanges {
    @Input() public move: Move;
    @Input() public energyStop: EnergyStop;

    public linkedMove: Move;
    public hasGas: boolean;
    public hasElectricity: boolean;

    public readonly i18nKeys = appI18nKeyTypes;
    public readonly meterTypes = ElectricityMeterType;
    public readonly roles = Role;
    public readonly moverRoles = MoverRole;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly context = UiContext;

    constructor(private readonly adminModalService: AdminModalService) {}

    public ngOnInit(): void {
        this.linkedMove = this.move.linkedMove as Move;
    }

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) this.ngOnInit();
        this.hasGas = MoveUtils.hasGasEnergyType(this.energyStop?.energyStopActiveServices as unknown as EnergyType);
        this.hasElectricity = MoveUtils.hasElectricityEnergyType(this.energyStop?.energyStopActiveServices as unknown as EnergyType);
    }

    public openMeterReadingAsset(file: FileInput): void {
        this.adminModalService.openEnergyMeterReadingAssetModal(file, this.move, this.onAssetClosed);
    }

    public openEnergyDocumentAsset(file: FileInput): void {
        this.adminModalService.openEnergyDocumentAssetModal(file, this.move, this.onAssetClosed);
    }

    private onAssetClosed = (move: Move): void => {
        if (move) this.move = move;
    };
    protected readonly UiContext = UiContext;
    protected readonly EnergyType = EnergyType;
    protected readonly ButtonAppearance = ButtonAppearance;
}
