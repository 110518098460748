<app-check-item [checked]="!move.linkedMove && !!move.energyMeterTransferDocumentsMovingAddressWithoutContract">
    <div ngProjectAs="checked">
        <span class="u-margin-right-half">{{
            'ADMIN.DASHBOARD.DETAIL.ENERGY.LEAVER_NOTIFIED'
                | translate: { person: (!!move.linkedMove ? 'MOVE.MOVER_ROLE.LEAVER' : 'REAL_ESTATE_AGENT.LABEL') | translate }
        }}</span>
        <a *ngIf="!move.linkedMove" (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
    </div>
    <div ngProjectAs="unchecked">
        {{
            'ADMIN.DASHBOARD.DETAIL.ENERGY.LEAVER_NOTIFIED'
                | translate: { person: (!!move.linkedMove ? 'MOVE.MOVER_ROLE.LEAVER' : 'REAL_ESTATE_AGENT.LABEL') | translate }
        }}

        <button
            app-button
            class="u-margin-top-half"
            *ngIf="!move.linkedMove"
            (click)="check()"
            [appearance]="buttonAppearance.Stroked"
            [context]="uiContext.Secondary"
            [disabled]="!move.moveStates.meterComplete"
        >
            {{ 'CHECK' | translate }}
        </button>
    </div>
</app-check-item>
