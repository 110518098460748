<app-todo
    *ngIf="move"
    [checked]="energyStop?.status === ServiceStatus.Submitted"
    [label]="'DETAIL.ENERGY_STOP.REGULARIZATION_BILL_REQUESTED' | i18nKeyTranslate: i18nKeys.AdminDashboard"
>
    <app-todo-template *todoStateUnchecked>
        <a
            *ngIf="energyStop?.status === ServiceStatus.ReadyToSubmit"
            app-button
            [context]="uiContext.Secondary"
            [size]="buttonSize.Sm"
            (click)="requestBill()"
        >
            {{ 'REQUEST' | translate }}
        </a>
    </app-todo-template>

    <app-todo-template *todoStateChecked>
        <a
            app-button
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Stroked"
            [size]="buttonSize.Sm"
            (click)="requestBill()"
            data-testid="leaver-regularization-bill-resend-button"
        >
            {{ 'RESEND' | translate }}
        </a>
        <a app-button [context]="uiContext.Secondary" [size]="buttonSize.Sm" (click)="downloadEDS()">{{ 'DOWNLOAD' | translate }}</a>
    </app-todo-template>
</app-todo>
@switch (energyStop?.status) {
    @case (ServiceStatus.Submitted) {
        <div class="u-margin-top-half u-margin-left-double">
            <button smvd-ui-button (click)="uncheck()" [context]="uiContext.Alt">
                {{ 'ADMIN.DASHBOARD.DETAIL.ENERGY_STOP.UN_SUBMIT' | translate }}
            </button>
        </div>
    }
    @default {
        <div class="u-margin-top-half u-margin-left-double">
            <button smvd-ui-button (click)="check()" [context]="uiContext.Alt">
                {{ 'ADMIN.DASHBOARD.DETAIL.ENERGY_STOP.SUBMIT' | translate }}
            </button>
        </div>
    }
}
