<form [formGroup]="form" (ngSubmit)="onSubmit()" class="u-w40vw">
    <app-energy-stop-suppliers-form
        [formControlName]="formControlNames.suppliers"
        [energyStop]="energyStopStore.energyStop()"
    ></app-energy-stop-suppliers-form>

    <app-check-input
        [option]="differentAddress"
        [hasMargin]="false"
        [hasMarginDouble]="true"
        [custom]="false"
        width="auto"
        class="u-display-block"
        [formControl]="differentAddressControl"
    >
    </app-check-input>

    <smvd-ui-address-input
        [label]="'ENERGY.ADDRESS_FINAL_INVOICE' | translate"
        *ngIf="!!differentAddressControl?.value"
        [formControlName]="formControlNames.contactAddress"
        class="u-margin-bottom-double"
    ></smvd-ui-address-input>

    <button
        app-button
        *ngIf="energyStopStore.energyStop().status !== ServiceStatus.Submitted"
        [context]="uiContext.Secondary"
        [isLoading]="buttonLoading$ | async"
        type="submit"
    >
        {{ 'SAVE' | translate }}
    </button>
</form>
