app-edit-review {
    .__form {
        display: grid;
        grid-gap: var(--spacer-default);
        grid-template-areas:
        'suggestion suggestion'
        'assessedTo assessedTo'
        'score sentiment';
    }

    #suggestion {
        grid-area: suggestion;
    }

    #assessed-to {
        grid-area: assessedTo;
    }

    #score {
        grid-area: score;
    }

    #sentiment {
        grid-area: sentiment;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix.mat-form-field-infix {
        width: auto;
    }

}
