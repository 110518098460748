import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-energy-documents-moving-address',
    template: `
        <div [ngSwitch]="!!move?.energyDocumentsMovingAddressByAdmin">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.energyDocumentsMovingAddressByAdmin }">
                <div data-testid="checked-true" *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.ENERGY_DOCUMENT' | translate }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div data-testid="checked-false" *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.NO_ENERGY_DOCUMENT' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half">
                <app-button
                    *ngSwitchCase="false"
                    (onClick)="check()"
                    [disabled]="!move?.moveStates?.meterComplete"
                    theme="secondary-outline"
                    >{{ 'CHECK' | translate }}
                </app-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyDocumentsMovingAddressComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public check(): void {
        this.moveSandbox.patchProperty(
            'energyDocumentsMovingAddressByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'energyDocumentsMovingAddressByAdmin',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }
}
